$(document).ready(function ($) {
    $('#changeTariff_btn').on('click', function (e) {
        e.preventDefault();
        let isOldKtv = $('#isOldKtv').text(),
            tariffId = $('#changeTariff_value').val();
        if (tariffId.length === 0) alert('Пожалуйста выберете тариф');
        else {
            if (isOldKtv) {
                if (confirm('Вы согласны, что абонентская плата за Кабельное ТВ теперь будет составлять 120 рублей в месяц?')) {
                    $('#changeTariff_form').submit();
                }
            } else $('#changeTariff_form').submit();
        }
    })

    $('#changeTariff_value').on('change', function (e) {
        let speed = $('#changeTariff_value option:selected').data('speed');
        console.log(speed);
        if(speed !== undefined) {
            $('#speed').show();
            $('#speed span').html(speed + " м/бит");
        }
        else $('#speed').hide();
    });
});
