$('document').ready(function ($) {
    const $noticeDing = $('#noticeDing');
    const $noticeCount = $('#noticeCount');

    let $notifications = undefined,
        $notificationsCount = 0;

    const getNotifications = async () => {
        if ($notifications !== undefined) return $notifications;
        else return $notifications = await axios.get('/user/notifications');
    }

    const notificationsList = (notice) => {
        let data = $('' +
            '<li>\n' +
            '   <div class="dropdown-item">\n' +
            '       <h5>' + notice.title + '</h5>\n' +
            '       <p style="white-space: break-spaces">' + notice.text + '</p>\n' +
            '       <div class="d-flex w-75 m-2 justify-content-between">\n' +
            '           <button data-id="' + notice.id + '" class="btn btn-success">OK</button>\n' +
            '           <span>' + notice.created_at + '</span>\n' +
            '       </div>\n' +
            '   </div>\n' +
            '   <hr class="dropdown-divider">\n' +
            '</li>');

        data.on('click', function (e) {
            e.stopPropagation();
            if (e.target.tagName === 'BUTTON') {
                let id = $(e.target).data('id');
                axios.post('/user/notifications/accept', {id}).then(r => {
                    if ($notificationsCount !== 0) {
                        $notificationsCount--;
                        $noticeCount.attr('data-count', $notificationsCount);
                        if($notificationsCount === 0) $noticeDing.find('ul').append('<li><div class="dropdown-item"><p style="padding: 1em 2rem">Нет новых уведомлений</p></div></li>');

                        $(this).hide('slow', () => {
                            if ($notificationsCount === 0) {
                                $noticeCount.addClass('none');
                            }
                        });
                    }

                })
            }
        })
        return data;
    }
    if ($noticeDing.length !== 0) {
        (function () {
            getNotifications().then(r => {
                $notificationsCount = 0;
                if (r.data.length !== 0) {
                    r.data.forEach(value => {
                        if (value.accepted === 0) {
                            $notificationsCount++;
                            $noticeDing.find('ul').append(notificationsList(value.notifications));
                        }
                    });
                } else $noticeDing.find('ul').append('<li><div class="dropdown-item"><p style="padding: 1rem 2rem">Нет новых уведомлений</p></div></li>');
                if ($notificationsCount !== 0) {
                    $noticeCount.attr('data-count', $notificationsCount).removeClass('none');
                }
            }).catch(e => {
                console.log(e);
            })
        })();
    }
});
