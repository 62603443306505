$('document').ready(function ($) {
    /**
     * Редирект при клику на Logo
     */
    $('.logo').on('click', function (e) {
        window.location = $(this).attr('href');
    });

    /**
     * Выпадающее меню сверху
     */
    $('.menu .drop_menu').on('click', 'a', function (e) {
        if ($(this).siblings("ul").is(':visible')) $(this).siblings("ul").hide('slow');
        else $(this).siblings("ul").show('slow');
    });

    /**
     * Временное отключение кнопки с классом btn
     * @type {boolean}
     */
    $('body').on('click', '.btn', function (e) {
        e.preventDefault();
        $(this).prop("disabled", true);
        setTimeout(() => {
            $(this).prop("disabled", false)
        }, 2000);
    });
});

