export function timer(CountSeconds) {
    let defaultCountSeconds = CountSeconds ?? 45;
    let int, seconds, val = localStorage.getItem('seconds');
    if (val) seconds = val;
    else seconds = defaultCountSeconds;
    int = setInterval(function () {
        if (seconds > 0) {
            seconds--; // вычитаем 1
            $('.seconds').text(seconds);
            localStorage.setItem("seconds", seconds);
        } else clearInterval(int);
    }, 1000);
}

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}