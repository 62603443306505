$('document').ready(function (e) {
    let $currentDate = new Date();
    if ($('#blocksReport').length > 0) {
        $.datetimepicker.setLocale('ru');
        $('#inputReport-start').datetimepicker({
            format: 'H:i d-m-Y',
            minDate: $currentDate.setMonth($currentDate.getMonth() - 12, 1)
        });
    } else {
        $.datetimepicker.setLocale('ru');
        $('#inputReport-start').datetimepicker({
            format: 'H:i d-m-Y',
            minDate: $currentDate.setMonth($currentDate.getMonth() - 3, 1)
        });
    }

    $('#inputReport-end').datetimepicker({
        format: 'H:i d-m-Y',
    });
});
