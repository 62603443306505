$('document').ready(function ($) {
    const qualityBtn = $('#quality_btn');
    /**
     * Кнопка статус интернета
     */
    $('#int_status').on('click', function (e) {
        let obj = $('#int_status');
        axios.get('changeIntStatus').then(r => {
            if (r.data !== undefined) {
                obj.removeClass();
                switch (r.data) {
                    case 0:
                        obj.html('Выключен');
                        obj.addClass('int_status_disable');
                        break;
                    case 1:
                        obj.html('Включен');
                        obj.addClass('int_status_enable');
                        break;
                }
            }
        }).catch(e => {
            if (e.response.data !== undefined) toastr.error(e.response.data);
        })
    });

    /**
     * Голосовалка
     * @type {boolean}
     */
    let flag = true;
    qualityBtn.mouseover(function (e) {
        setTimeout(function () {
            $("#txt").css("display", "none");
        }, 6000);
    });

    /**
     * Голосовалка
     * @type {boolean}
     */
    let flag2 = true;
    qualityBtn.on('click', function (e) {
        e.preventDefault();
        let text = $('.modal-body textarea').val(),
            attr = $('.rating-area input[type=radio]:checked');
        if (attr && attr.val() <= 3 && text === '' && flag === true) {
            flag = false;
            $('.modal-body textarea').addClass('blink');
            $("#txt").css("display", "block");
        } else if (attr && attr.val() >= 4 && flag === true) {
            flag = false;
            alert('Спасибо, оставьте еще отзыв на Flamp. Ссылка под кнопкой или нажмите еще раз :)');
            $('#flamp').css("display", "block");
        } else {
            if (flag2 === true) $('.modal-content form').submit();
            flag2 = false;
        }
    });
});