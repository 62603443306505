$('document').ready(function ($) {
    $('.tab').on('click', function (e) {
        //window.location = $(this).attr('href');
        if ($(this).attr('href')) {
            window.location = $(this).attr('href')
        }
    });

    $('#promised_form_btn').on('click', function (e) {
        e.preventDefault();
        let cost = $('#promised_form_value').val();
        if (cost.length === 0) toastr.warning('Выберете сумму платежа');
        else $('#promised_form').submit();
    });
});
