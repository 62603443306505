import {timer} from './functions'

$(document).ready(function () {
    $('#ivi_bundle').show();
    const $addIvi = $('#addIvi'), $dialogIvi = $('#dialog_ivi'), $vendorId = 1;

    let $options,
        $typeData,
        $tariffsTable = false,
        $seconds = localStorage.getItem('seconds');

    if ($seconds === '0' || $seconds === null) localStorage.removeItem('seconds');
    else localStorage.setItem('seconds', $seconds);


    function validateData(data) {
        if (data.match(/^((\+7|7|8)+([0-9]){10})$/)) return 'phone';
        if (data.match(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/)) return 'email';
        return false;
    }

    function emptyForm() {
        $('#data input[name=phone]').remove();
        $(".dialog_form").trigger('reset');
        $(".error").text('');
    }

    if ($('#ivi_tariffs').length) $tariffsTable = true;

    $('#add_ivi_bundle_btn').on('click', function (event) {
        event.preventDefault();
        emptyForm();
        if (!($addIvi.hasClass('disabled'))) $addIvi.addClass('ui-state-disabled');
        $options = 'subscription:bundle';
        $dialogIvi.dialog('open');
    });

    $('.tabs li').on('click', function (event) {
        $('.tabs .tabLinks').each(function (index, elem) {
            $($(elem).children('a').attr('href')).hide();
            $(elem).removeClass('active');
        });
        $(this).addClass('active');
        $($(this).children('a').attr('href')).show();

    });

    $('#change_update_ivi_btn').on('click', function (e) {
        e.preventDefault();
        axios.post('/ivi/changeUpdate').then(r => {
            switch (r.data) {
                case 1:
                    $('#renew').html('Включено');
                    break;
                case 0:
                    $('#renew').html('Отключено');
                    break;
            }
        }).catch(e => {
            if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data);
        })
    });

    $('#add_ivi_option_btn').on('click', function (e) {
        e.preventDefault();
        emptyForm();
        if (!($addIvi.hasClass('disabled'))) $addIvi.addClass('ui-state-disabled');
        $options = 'subscription:option';
        $tariffsTable = false;
        $dialogIvi.dialog('open');
    });

    $dialogIvi.dialog({
        autoOpen: false,
        modal: true,
        resizable: true,
        closeOnEscape: true,
        dialogClass: 'dialog_ivi',
        width: 450,
        height: 400,
        buttons: [
            {
                id: 'addIvi',
                class: 'btn btn-primary',
                style: 'font-size: 15px',
                text: 'Отправить',
                click: function () {
                    let tariffId = $('#ivi_tariffs input[type=radio]:checked').val(),
                        data = $('#ivi_data_form input').val();
                    if (!($typeData = validateData(data))) toastr.warning('Введены не верные данные');
                    else if ($tariffsTable === true && !tariffId) toastr.warning('Пожалуйста, выберете тариф');
                    else {
                        axios.post('/additionServices/storeData', {
                            data: data, tariffId: tariffId, vendorId: $vendorId, options: $options,
                        }).then(r => {
                            switch (r.status) {
                                case 200:
                                    if (r.data === 'phone' && $typeData === 'phone') {
                                        localStorage.removeItem('seconds');
                                        $dialogIvi.dialog('close');
                                        $('#data').append("<input id='phone' type='hidden' name='phone' value='" + data + "'>");
                                        if (!($('#sendCode').hasClass('disabled'))) $('#sendCode').addClass('ui-state-disabled');
                                        $('#dialog_check_sms').dialog('open');
                                        timer();
                                    } else if (r.data === 'email' && $typeData === 'email') {
                                        $dialogIvi.dialog('close');
                                        $('#dialog_sent_email').dialog('open');
                                    }
                                    break;
                                case 205:
                                    timer();
                                    toastr.warning('Смс была недавно отправленна. Подождите: ' + localStorage.getItem('seconds') + ' сек.');
                                    break;
                                default:
                            }
                        }).catch(e => {
                            if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                        });
                    }
                }
            },
            {
                id: 'cancelIvi',
                class: 'btn btn-secondary',
                text: 'Отмена',
                style: 'font-size: 15px',
                click: function () {
                    emptyForm();
                    $(this).dialog('close');
                }
            }
        ]
    });

    $('#dialog_check_sms').dialog({
        autoOpen: false,
        modal: true,
        resizable: true,
        closeOnEscape: true,
        dialogClass: 'dialog_ivi',
        width: 300,
        height: 250,
        buttons: [
            {
                id: 'sendCode',
                class: 'btn btn-primary',
                style: 'font-size: 15px',
                text: 'Отправить',
                click: function (e) {
                    axios.post('additionServices/checkSms', {
                        code: $('#code').val(),
                        phone: $('#data input[name=phone]').val(),
                    }).then(r => {
                        window.location = 'additionServices/success';
                    }).catch(e => {
                        if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                    });
                }
            },
            {
                id: 'cancelSend',
                class: 'btn btn-secondary',
                text: 'Отмена',
                style: 'font-size: 15px',
                click: function () {
                    emptyForm();
                    $(this).dialog('close');
                }
            }
        ]
    });


    $('#dialog_sent_email').dialog({
        autoOpen: false,
        modal: true,
        resizable: true,
        closeOnEscape: true,
        dialogClass: 'dialog_ivi',
        width: 300,
        height: 250,
        buttons: [
            {
                id: 'okSend',
                class: 'btn-primary',
                text: 'Ок',
                style: 'font-size: 15px',
                click: function () {
                    $(this).dialog('close');
                }
            }
        ]
    });

    $('#ivi_data_form input').on('input', function (e) {
        let to = false;
        if (to) clearTimeout(to);
        to = setTimeout(function () {
            let search = $('#ivi_data_form input').val();
            if (validateData(search)) {
                $addIvi.removeClass('ui-state-disabled');
            } else if (!($addIvi.hasClass('disabled'))) $addIvi.addClass('ui-state-disabled');
        }, 250);
    });

    $('#code').on('input', function (e) {
        let to = false;
        if (to) clearTimeout(to);
        to = setTimeout(_ => {
            let search = $(this).val();
            if (search.toString().length === 6) {
                $('#sendCode').removeClass('ui-state-disabled');
            } else if (!($('#sendCode').hasClass('disabled'))) $('#sendCode').addClass('ui-state-disabled');
        }, 250);
    });
});
