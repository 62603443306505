$(document).ready(function ($) {
    let $mobilesFromBtn = $('#change_mobiles_btn'),
        $mobilesForm = $('#change_mobiles_form');

    let $numbers = $mobilesForm.find('input[name^=mobTel]').map(function () {
        return $(this).val();
    });
    if ($numbers.length === 0 || $numbers[0] === '') $mobilesFromBtn.prop('disabled', 'disabled');

    $mobilesForm.on('input', 'input', function (e) {
        let patternMobile = new RegExp(/^((8|\+7|7)[\- ]?)?[\d\- ]{10}$/);
        let numbers = $mobilesForm.find('input[name^=mobTel]').map(function () {
            return $(this).val();
        });
        $(numbers).each((index, value) => {
            if (patternMobile.test(value) || (value === '' && index !== 0)) $mobilesFromBtn.prop('disabled', '');
            else $mobilesFromBtn.prop('disabled', 'disabled');
        });
    }).on('click', '#change_mobiles_btn', function (e) {
        e.preventDefault();
        let mobiles = [],
            mobTel = $('#change_mobiles_form').find('input[class^=mobTel]');
        mobTel.each((index, value) => {
            if ($(value).val() !== '') mobiles[index] = $(value).val()
        });
        axios.post('/settings/changeMobiles', {mobTel: mobiles}).then(r => {
            toastr.success('Изменение номера телефона прошло успешно');
        }).catch(e => {
            if (e.response.data !== undefined) toastr.error(e.response.data);
        });
    });

    $('#change_password_btn').on('click', function (e) {
        e.preventDefault();
        let oldPass = $('#old_pass').val(),
            newPass = $('#new_pass').val(),
            newPassRepeat = $('#repeat_new_pass').val();
        if (oldPass === '' || newPass === '' || newPassRepeat === '') {
            toastr.warning('Некоторые поля пустые');
        } else {
            axios.post('/settings/changePassword', {
                oldPass, newPass, newPassRepeat,
            }).then(r => {
                toastr.success('Изменение пароля прошло успешно');
            }).catch(e => {
                if (e.response.data !== undefined) toastr.error(e.response.data);
            });
        }
    });

    let fl = false;
    $('#smsNotice_btn').on('click', function (e) {
        if (fl === true) return;
        fl = true;
        setTimeout(_ => {
            fl = false
        }, 1000)
        e.preventDefault();
        let smsNotice = $('#smsNotice'),
            smsData = $('.smsData'),
            numbers = $mobilesForm.find('input[name^=mobTel]').map(function () {
                return $(this).val();
            });
        smsData.css('background-color', '#ddd8d2');
        if (numbers[0] || numbers[1] || numbers[2]) {
            axios.get('/settings/changeSmsNotice').then(r => {
                smsNotice.prop('checked', !smsNotice.is(':checked'));
                if (smsNotice.is(':checked') === true) $(this).text('Включено');
                else $(this).text('Отключено');
                smsData.css('background-color', '#0096db');
            }).catch(e => {
                smsData.css('background-color', '#0096db');
                if (e.response.data !== undefined) toastr.error(e.response.data);
            });
        } else {
            smsData.css('background-color', '#0096db');
            toastr.warning('Включение будет возможно только после указания мобильного телефона');
        }
    });

    $('#changeEmail').on('click', function (e) {
        e.preventDefault();
        axios.post('/settings/changeEmail', {
            email: $('#input_email').val()
        }).then(r => {
            toastr.success('Почта успешно изменена')
        }).catch(e => {
            if (e.response.data !== undefined) toastr.error(e.response.data);
        })
    });
});
