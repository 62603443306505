import {timer} from './functions'

$(document).ready(function () {
    let seconds = localStorage.getItem('seconds');

    $('#auth_form .message').text();

    $('#auth_form_btn').on('click', function (e) {
        e.preventDefault();
        let login = $('#auth_form input[name=login]').val(),
            password = $('#auth_form input[name=password]').val();
        if (login && password) {
            axios.post('/auth/auth', {
                login, password
            }).then(r => {
                if (r.data.phones !== undefined) {
                    r.data.phones.forEach(function (phone, index) {
                        $('#phones').append('<div class="phone"><input type="radio" name="phone" value="' + index + '">' + phone + '</div>')
                    });
                    $('#dialog_newuser').dialog('open');
                } else window.location = '/main';
            }).catch(e => {
                if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
            });
        } else toastr.error('Пустые значения не допустимы');
    });


    $('#dialog_newuser').dialog({
        autoOpen: false,
        modal: true,
        dialogClass: 'dialog_authSms',
        closeOnEscape: true,
        width: 500,
        height: 350,
        buttons: [
            {
                id: 'addPhone',
                class: 'btn btn-primary',
                style: 'font-size: 15px',
                text: 'Отправить',
                click: function () {
                    let phone = $('#dialog_newuser input[type=radio]:checked').val();
                    axios.post('/auth/sendSms', {phone}).then(r => {
                        switch (r.status) {
                            case 204:
                                localStorage.removeItem('seconds');
                                $('#data').append("<input id='phone' type='hidden' value='" + phone + "'>");
                                $('#dialog_newuser').dialog('close');
                                $('#dialog_newuser_checksms').dialog('open');
                                timer();
                                break;
                            case 205:
                                toastr.warning('Смс была недавно отправленна. Подождите: ' + localStorage.getItem('seconds') + ' сек.')
                                timer();
                                break;
                        }
                    }).catch(e => {
                        if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                    });
                }
            },
            {
                id: 'cancelPhone',
                class: 'btn btn-danger',
                text: 'Отмена',
                style: 'font-size: 15px',
                click: function () {
                    $(this).dialog('close');
                }
            }
        ],
        close: function () {
            emptyForm();
        }
    });

    $('#dialog_newuser_checksms').dialog({
        autoOpen: false,
        modal: true,
        dialogClass: 'dialog_authSms',
        closeOnEscape: true,
        width: 350,
        height: 250,
        buttons: [
            {
                id: 'sendCode',
                class: 'btn btn-primary',
                style: 'font-size: 15px',
                text: 'Отправить',
                click: function () {
                    let login = $('#auth_form input[name=login]').val(),
                        password = $('#auth_form input[name=password]').val(),
                        code = $('#code').val(),
                        phone = $('#phone').val();
                    axios.post('/auth/checkSms', {
                        phone, code, login, password
                    }).then(r => {
                        window.location = '/';
                    }).catch(e => {
                        if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                    });
                }
            },
            {
                id: 'cancelSend',
                class: 'btn btn-default',
                text: 'Отмена',
                style: 'font-size: 15px',
                click: function () {
                    emptyForm();
                    $(this).dialog('close');
                }
            }
        ],
        close: function () {
            emptyForm();
        }
    });

    function emptyForm() {
        $('#phones .phone').remove();
        $(".dialog_form").trigger('reset');
        $(".error").text('');
    }

    if (seconds === '0') localStorage.removeItem('seconds');
    else localStorage.setItem('seconds', seconds);
});
