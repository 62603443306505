$(document).ready(function (e) {
    const $parentAccount = $('#parentAccountInfo');

    $('.LOGO').on('click', function (e) {
        window.location = $(this).attr('href');
    });

    /**
     * @param accountData{{tariffs:object,account:object,
     * basic_account:number,address:string, tariffName:string,
     * is_blocked:number, int_status:number, balance:string, credit:string}} object
     * @returns {undefined}
     */
    function createLinkedAccount(accountData = {}) {
        let tariffName = '',
            accountId = accountData.basic_account,
            account = undefined,
            isBlocked = Number(accountData.account.is_blocked),
            intStatus = Number(accountData.account.int_status);

        if (accountData.account.tariffs.length === 0) tariffName = 'Тариф отсутствует';
        else tariffName = accountData.account.tariffs[0].tariffName
        if (accountId !== 0) {
            account = $(
                '<div class="basic">\n' +
                '   <h2>\n' +
                '       <label>Лицевой счет: </label><span id="account_' + accountId + '">' + accountId + '</span>\n' +
                '        <a class="detail" href="groups/reports/generalReport?accountId=' + accountId + '">Отчеты...</a>\n' +
                '   </h2>\n' +
                '   <hr>\n' +
                '   <div id="account" class="data_account">\n' +
                '    <div class="block_acc"><div class="data">\n' +
                '    <div class="long">\n' +
                '       <div>\n' +
                '           <label>Адрес:</label><span>' + accountData.address + '</span>\n' +
                '       </div>\n' +
                '       <div>\n' +
                '           <label>Подключеный тариф:</label><span>' + tariffName + '</span>\n' +
                '       </div>\n' +
                '    </div>\n' +
                '    <div class="short">\n' +
                '    </div>\n' +
                '</div>'
            );
        }
        if (account !== undefined) {
            if (accountData.balance !== '0') {
                account.find('.data .long').append('<div><label>Баланс:</label><span>' + accountData.account.balance + '</span></div>');
            }
            if (accountData.credit !== '0') {
                account.find('.data .long').append('<div><label>Кредит:</label><span>' + accountData.account.credit + '</span></div>');
            }
            let blocking = $(
                '<div>\n' +
                '   <label>Приостановка:</label>\n' +
                '       <div class="suspence_status">\n' +
                '           <input id="suspence_status_' + accountId + '" type="checkbox"/>\n' +
                '   <label for="suspence_status_' + accountId + '">Нет<span></span>Да</label>\n' +
                '   </div>\n' +
                '</div>'
            );
            if (isBlocked !== 0) blocking.find('input[type=checkbox]').prop('checked', true);

            let internetStatus = $(
                '<div>\n' +
                '   <label>Статус интернета:</label>\n' +
                '   <span class="int_status"></span>\n' +
                '</div>'
            );

            if (intStatus === 0) {
                internetStatus.addClass('int_status_disable');
                internetStatus.find('span').text('Выключен')
            } else {
                internetStatus.addClass('int_status_enable');
                internetStatus.find('span').text('Включен')
            }

            /**
             * Включение/Отключение блокировки
             */
            (function () {
                let timer = new Date() - 3000;
                blocking.find('input').on('change', function (e) {
                    let suspense = '';
                    if ((new Date() - timer) < 3000) {
                        this.checked = !this.checked;
                        return false;
                    }
                    timer = new Date();
                    axios.post('/groups/setSuspense', {
                        suspense: !isBlocked,
                        accountId: accountId
                    }).then(r => {
                        if (isBlocked !== 0) {
                            isBlocked = 0;
                            internetStatus.removeClass().addClass('int_status_enable');
                            internetStatus.find('span').text('Включен')
                        } else {
                            isBlocked = 768;
                            internetStatus.removeClass().addClass('int_status_disable');
                            internetStatus.find('span').text('Выключен')
                        }
                        toastr.success('Установка блокировки на л.с. ' + accountId + ' произошла успешно');
                    }).catch(e => {
                        this.checked = !this.checked;
                        if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                    });
                });
            }());

            /**
             * Включение/Отключение интернета
             */
            internetStatus.on('click', function (e) {
                axios.post('/groups/changeIntStatus', {
                    accountId: accountId,
                    intStatus: !intStatus
                }).then(r => {
                    if (intStatus === 1) {
                        intStatus = 0;
                        internetStatus.removeClass().addClass('int_status_disable');
                        internetStatus.find('span').text('Выключен')
                    } else {
                        intStatus = 1;
                        internetStatus.removeClass().addClass('int_status_enable');
                        internetStatus.find('span').text('Включен')
                    }
                    toastr.success('Успешное изменение статуса интернета ')
                }).catch(e => {
                    if (e.response.data !== undefined || e.response.data !== '') toastr.error(e.response.data)
                })
            });
            account.find('.data .short').append(blocking).append(internetStatus);
        }
        return account;
    }

    if ($parentAccount.length !== 0) {
        axios.get('groups/getLinkedAccounts').then(r => {
            $.each(r.data, function (index, accData) {
                $('.bars ul').append('<li class="go-to"><a href="#account_' + accData.basic_account + '">' + accData.address + '</a></li>');
                $('.menu ul').append('<li class="go-to"><a href="#account_' + accData.basic_account + '">' + accData.address + '</a></li>');
                $(".listAccounts").append(createLinkedAccount(accData));
            });
        });
    }

    $('.left_bar, .menu').on('click', '.go-to', function (e) {
        let target = $(this).find('a').attr('href');
        if (target.length !== 0) {
            $('html,body').animate({
                scrollTop: $(target).offset().top - $(window).height() / 2
            }, 750, _ => {
                $(target).parents('.basic').removeClass('animate_shakeX').addClass('animate_shakeX');
            });
        }
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() >= 100) $('#goTop').fadeIn(200);
        else $('#goTop').fadeOut(200);
    });

    $("#goTop").on('click', function (e) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
});
