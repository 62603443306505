//window.$ = window.jQuery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.toastr = require('toastr');

require("bootstrap");
require('jquery-ui/ui/widgets/dialog')
require('jquery-datetimepicker')
require('./default')
require('./notificatons')
require('./auth')
require('./main')
require('./ivi')
require('./payments')
require('./reports')
require('./tariffs')
require('./settings')
require('./group')
